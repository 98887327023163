import gsap from 'gsap';
import ScrollTrigger from 'gsap/scrolltrigger';
gsap.registerPlugin(ScrollTrigger);

const altriElementi = document.querySelectorAll(".elemento");

// Animazione iniziale per altri elementi
altriElementi.forEach((el, index) => {
  gsap.from(el, {
    y: -250, // Partenza dall'alto
    opacity: 0,
    delay: index * 0.2, // Ritardo basato sull'indice
    duration: 3,
    ease: "elastic.inOut(1, 0.3)",
  });
});

// Animazione specifica per #bonus


gsap.from("#bonus", { 
  y:-250,
  delay: altriElementi.length * 0.2, // Assicurati che #bonus sia l'ultimo
  opacity:0,
})


gsap.to("#bonus", {
  duration:3,
ease: "elastic.inOut(1,0.3)",
  opacity:1,
  y: "110px",
  });




gsap.to(".elica", {
  rotation: 360, // Rotazione di 360 gradi
  transformOrigin: "116.6px 131px", // Sostituisci con le coordinate del tuo pivot
  duration: 60, // Durata dell'animazione in secondi
  ease: "linear", // Tipo di easing
  repeat: -1 // Ripeti all'infinito
});

gsap.set("#nuvoletta", { 
  opacity:0.2,
});
gsap.to("#nuvoletta", {
  x: "+=200", // Sposta di 200px a destra
  duration: 10, // Durata dell'animazione (2 secondi per esempio)
  ease: "power1.inOut", // Tipo di easing per un effetto morbido
  yoyo: true, // Abilita l'effetto yoyo
  repeat: -1, // Ripeti all'infinito
  yoyoEase: true // Applica l'easing anche al ritorno
});

