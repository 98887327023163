// Prima importa jQuery
import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;

// Importa Bootstrap e stili personalizzati
import 'bootstrap';
import './style.scss';
import './risorse/script/animazioni.js';

import 'animate.css';


// Importa Owl Carousel in modo dinamico
import('owl.carousel/dist/assets/owl.carousel.css');
import('owl.carousel').then(() => {
    // Qui puoi inizializzare Owl Carousel, perché sicuramente jQuery è stato caricato
    
    // Altre inizializzazioni relative a Owl Carousel

    $(document).ready(function(){
     
      $("#carosello-testa").owlCarousel({
        loop            : true      , // Loop all'infinito
        margin          : 10        , // Spazio tra gli elementi
        nav             : true      , // Mostra le frecce di navigazione
        autoplay        : true      ,
        dots            : false     ,
        autoplayTimeout : 3000      ,
        responsive      : {
          0: {
            items: 1 // Numero di elementi da mostrare per diversi breakpoint
          },
          600: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      });

    });

      
    $("#carosello-recensioni").owlCarousel({
        // Opzioni di Owl Carousel
        loop: true, // Loop all'infinito
        margin: 10, // Spazio tra gli elementi
        nav: true, // Mostra le frecce di navigazione
        // autoplay:true,
        dots:true,
        responsive: {
            0: {
                items: 1,
                nav:false
            },
            600: {
                items: 2,
                nav: false
            },
            1000: {
                items: 2
            }
        }
    });

});


jQuery(document).ready(function($) {
  // Funzione per unificare l'altezza dei blocchi
  function unifyHeights() {
    var maxHeight = 0;
    // Trova tutti gli elementi con la classe 'unifica-height' e itera su di essi
    $('.unifica-height').each(function() {
      // Ottiene l'altezza di ciascun blocco
      var height = $(this).height();
      // Aggiorna l'altezza massima se questa è maggiore dell'altezza corrente massima
      if (height > maxHeight) { maxHeight = height; }
    });
    // Applica l'altezza massima a tutti i blocchi
    $('.unifica-height').css('min-height', maxHeight);
  }

  // Chiama la funzione quando la pagina ha completato il caricamento
  $(window).on('load', function() {
    unifyHeights();
  });

  // Aggiunge un listener per l'evento resize della finestra
  $(window).resize(function() {
    unifyHeights();
  });
});

